// dev
export const config =  {
    baseUrl: 'https://pbapi.dev.boo2.nl',
    portal_url: 'https://volvo.dev.boo2.nl',
}

// staging
// export const config =  {
//     baseUrl: 'https://pbapistaging.dev.boo2.nl/',
//     portal_url: 'https://volvo.staging.boo2.nl',
// }